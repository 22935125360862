import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Screen0 from "../../images/spet0.png";
import Screen1 from "../../images/spet1.png";
import Screen2 from "../../images/spet2.png";
import Screen3 from "../../images/spet3.jpg";
import Screen4 from "../../images/spet4.jpg";
import Group from "../../images/spetgroup.gif";
import Title0 from "../../images/spett0.png";
import Title1 from "../../images/spett1.png";
import Title2 from "../../images/spett2.png";

export default function SPet() {
  return (
    <Layout>
      <Helmet>
        <title>S. Pet | Nathan Pasko</title>
        <meta
          name="description"
          content="A tribute to the lo-fi virtual pets of the 1990s, S. Pet is a new virtual pet for Android, Mac, Windows, and Linux that features a multitude of different pixellated pets and palettes inspired by the GameBoy Color. Hatch a pet today and see who it grows into!"
        />
      </Helmet>
      <div className="project-page">
        <ul className="horizontal-scroll">
          <li>
            <picture>
              <img src={Screen0} alt="" height="1024" width="576" />
            </picture>
          </li>
          <li>
            <picture>
              <img src={Screen1} alt="" height="1024" width="576" />
            </picture>
          </li>
          <li>
            <picture>
              <img src={Screen2} alt="" height="1024" width="576" />
            </picture>
          </li>
          <li>
            <picture>
              <img src={Screen3} alt="" height="1024" width="576" />
            </picture>
          </li>
          <li>
            <picture>
              <img src={Screen4} alt="" height="1024" width="576" />
            </picture>
          </li>
        </ul>
        <h1>S. Pet</h1>
        <p>
          During the 1990s a number of virtual pets competed for attention:
          Bandai produced Tamagotchi and Digimon, Tiger produced the Giga
          Pet.... Nintendo produced Pikachu pets, and there was even a Jurassic
          Park dinosaur encyclopedia that featured a digital dino pet for fun. I
          created S. Pet as an homage to this long-faded fad, imitating the
          tiny, pixelated displays, simple sound effects, and basic simulation
          environments.
        </p>
        <iframe style={{marginBottom: '1.25rem'}} frameborder="0" src="https://itch.io/embed/222833?border_width=0&amp;fg_color=000&amp;link_color=e0e0e0&amp;border_color=fff" width="206" height="165"><a href="https://avknights.itch.io/s-pet">S. Pet by A.V. Knights</a></iframe>
        <p>
          The pets in S. Pet hatch from eggs and grow from babies to children to
          teens to adults over the course of about a week. As the pet owner, the
          player must feed their pet regularly, keep them happy, and either
          potty-train them or clean up after them. Failure to do these things
          will result in the pet's death.
        </p>
        <ul className="horizontal-scroll">
          <li>
            <picture>
              <img src={Title0} alt="" height="640" width="1024" />
            </picture>
          </li>
          <li>
            <picture>
              <img src={Title1} alt="" height="640" width="1024" />
            </picture>
          </li>
          <li>
            <picture>
              <img src={Title2} alt="" height="640" width="1024" />
            </picture>
          </li>
        </ul>
        <p>
          A lot has changed in electronics and gaming since Tamagotchi appeared
          in 1996. While many children today carry smart phones everywhere, the
          children of the '90s probably weren't carrying any other electronics
          around besides their v. pets. Because smart phones can provide so many
          functions to us as users, the idea of a pocket pet with such a
          specific use now seems somewhat foreign, and S. Pet not only competes
          for the player's attention against the real world, but against every
          other app on the player's phone. One of the first updates I pushed to
          the game was a way for the pet's digestion to cascade through Stomach,
          Happiness, and Discipline when the previous stat was depleted,
          allowing players to "forget" about their pet for a bit longer before
          death resulted. Everyone who owned a Tamagotchi or other v. pet
          remembers how demanding they could be.... In a world where our pockets
          are full of apps demanding our attention, punishing the player for
          getting distracted can feel unfair.
        </p>
        <picture>
          <img src={Group} alt="" height="648" width="1912" />
        </picture>
      </div>
    </Layout>
  );
}
